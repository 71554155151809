//Path: src/components/RepairTipsSection.js
import React from 'react';

const RepairTipsSection = ({ repairTips, handleArrayChange, handleAddRepairTip, handleRemoveRepairTip }) => {
  return (
    <div className="mb-6 p-4 border rounded-md">
        <label className="block text-gray-700 font-semibold mb-2">Repair Tips:</label>
              {repairTips.map((tip, index) => (
                <div key={index} className="mb-4 flex items-center">
        <textarea
          name="repairTips"
          value={tip}
          onChange={(e) => handleArrayChange(e, index, 'repairTips')}
          className="w-full px-3 py-2 border rounded-md"
          rows="4"
        />
          <button type="button" onClick={() => handleRemoveRepairTip(index)} className="ml-2 px-3 py-2 bg-red-500 text-white rounded-md">Remove</button>
        </div>
      ))}
      <button type="button" onClick={handleAddRepairTip} className="mb-4 px-4 py-2 bg-green-500 text-white rounded-md">Add Repair Tip</button>
    </div>
  );
};

export default RepairTipsSection;
