//Path: src/components/NoteList.js
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import moment from "moment";

const NoteList = () => {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("/api/notes")
      .then((response) => {
        // Sort notes by date
        const sortedNotes = response.data.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setNotes(sortedNotes);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleDelete = (id) => {
    axiosInstance
      .delete(`/api/notes/${id}`)
      .then(() => setNotes(notes.filter((note) => note._id !== id)))
      .catch((error) => console.error(error));
  };

  return (
    <div className="max-w-2xl mx-auto mt-8">
      <h1 className="text-2xl font-bold mb-4">Notes</h1>
      <Link
        to="/create"
        className="inline-block mb-4 px-4 py-2 bg-green-500 text-white rounded"
      >
        Create New Note
      </Link>
      <ul className="space-y-4">
        {notes.map((note, index) => (
          <li key={note._id} className="p-4 bg-gray-100 rounded shadow">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-lg font-semibold">
                  ID: {index + 1} - {note.title.slice(0, 25)}
                  {note.title.length > 25 && "..."}
                </h2>
                <p>
                  Date: {moment(note.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                </p>
                <p>
                  Lastmod:{" "}
                  {moment(note.lastmod, "YYYY-MM-DD").format("DD/MM/YYYY")}
                </p>
                <div>
                  <h3 className="font-semibold">Repairs:</h3>
                  {note.repairs.map((repair, repairIndex) => (
                    <div key={repairIndex} className="ml-4">
                      <p>Problem: {repair.problem}</p>
                      <p>Solutions:</p>
                      <ul className="list-disc list-inside">
                        {repair.solutions.map((solution, solutionIndex) => (
                          <li key={solutionIndex}>{solution}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                <div>
                  <h3 className="font-semibold">Repair Tips:</h3>
                  <ul className="list-disc list-inside ml-4">
                    {note.repairTips.map((tip, tipIndex) => (
                      <li key={tipIndex}>{tip}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="font-semibold">Downloads:</h3>
                  {note.downloads.map((download, downloadIndex) => (
                    <a
                      key={downloadIndex}
                      href={download.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-blue-500 underline"
                    >
                      {download.file}
                    </a>
                  ))}
                </div>
              </div>
              <div className="space-x-2">
                <Link
                  to={`/edit/${note._id}`}
                  className="px-4 py-2 bg-yellow-500 text-white rounded"
                >
                  Edit
                </Link>
                <button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this note?"
                      )
                    ) {
                      handleDelete(note._id);
                    }
                  }}
                  className="px-4 py-2 bg-red-500 text-white rounded"
                >
                  Delete
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NoteList;
