import React from 'react';

const RepairSection = ({ repairs, setFormData, formData }) => {
  const handleArrayChange = (e, index, arrayName) => {
    const { value } = e.target;
    const newArray = [...repairs];
    newArray[index][arrayName] = value;
    setFormData({ ...formData, repairs: newArray });
  };

  const handleNestedArrayChange = (e, outerIndex, innerIndex, nestedArrayName) => {
    const { value } = e.target;
    const newArray = [...repairs];
    if (!Array.isArray(newArray[outerIndex][nestedArrayName])) {
      newArray[outerIndex][nestedArrayName] = [];
    }
    newArray[outerIndex][nestedArrayName][innerIndex] = value;
    setFormData({ ...formData, repairs: newArray });
  };

  const handleAddRepair = () => {
    setFormData({ ...formData, repairs: [...repairs, { problem: '', solutions: [''] }] });
  };

  const handleRemoveRepair = (index) => {
    const newRepairs = [...repairs];
    newRepairs.splice(index, 1);
    setFormData({ ...formData, repairs: newRepairs });
  };

  const handleAddSolution = (repairIndex) => {
    const newRepairs = [...repairs];
    newRepairs[repairIndex].solutions.push('');
    setFormData({ ...formData, repairs: newRepairs });
  };

  const handleRemoveSolution = (repairIndex, solutionIndex) => {
    const newRepairs = [...repairs];
    newRepairs[repairIndex].solutions.splice(solutionIndex, 1);
    setFormData({ ...formData, repairs: newRepairs });
  };

  return (
    <div>
      {repairs.map((repair, index) => (
        <div key={index} className="mb-6 p-4 border rounded-md">
          <label className="block text-gray-700 font-semibold mb-2">Repair Problem:</label>
          <textarea
            name="problem"
            value={repair.problem}
            onChange={(e) => handleArrayChange(e, index, 'problem')}
            className="w-full px-3 py-2 border rounded-md"
            rows="4"
          />
          {repair.solutions?.map((solution, solIndex) => (
            <div key={solIndex} className="mt-2">
              <label className="block text-gray-600 font-semibold mb-2">Solution:</label>
              <textarea
                name="solution"
                value={solution}
                onChange={(e) => handleNestedArrayChange(e, index, solIndex, 'solutions')}
                className="w-full px-3 py-2 border rounded-md"
                rows="4"
              />
              <button
                type="button"
                onClick={() => handleRemoveSolution(index, solIndex)}
                className="ml-2 px-3 py-2 bg-red-500 text-white rounded-md"
              >
                Remove Solution
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => handleAddSolution(index)}
            className="mt-2 px-4 py-2 bg-green-500 text-white rounded-md"
          >
            Add Solution
          </button>
          <button
            type="button"
            onClick={() => handleRemoveRepair(index)}
            className="mt-2 px-4 py-2 bg-red-500 text-white rounded-md"
          >
            Remove Repair
          </button>
        </div>
      ))}
      <button type="button" onClick={handleAddRepair} className="mb-4 px-4 py-2 bg-blue-500 text-white rounded-md">
        Add Repair Problem
      </button>
    </div>
  );
};

export default RepairSection;
