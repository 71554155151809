import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NoteList from './components/NoteList';
import CreateNote from './components/CreateNote';
import EditNote from './components/EditNote';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<NoteList />} />
        <Route path="/create" element={<CreateNote />} />
        <Route path="/edit/:id" element={<EditNote />} />
      </Routes>
    </Router>
  );
}

export default App;
