import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import { useParams } from 'react-router-dom';
import NoteForm from './NoteForm';

const EditNote = () => {
  const { id } = useParams();
  const [note, setNote] = useState(null);

  useEffect(() => {
    axiosInstance.get(`/api/notes/${id}`)
      .then(response => setNote(response.data))
      .catch(error => console.error(error));
  }, [id]);

  if (!note) return <div>Loading...</div>;

  return <NoteForm isEdit={true} note={note} />;
};

export default EditNote;
