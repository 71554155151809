//Path: src/components/NoteForm.js
import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import RepairSection from "./RepairSection";
import RepairTipsSection from "./RepairTipsSection";
import DownloadsSection from "./DownloadsSection";
import moment from "moment";

const NoteForm = ({ isEdit, note }) => {
  const [formData, setFormData] = useState({
    title: "",
    date: moment().format("DD/MM/YYYY"),
    lastmod: moment().format("DD/MM/YYYY"),
    tags: [],
    draft: false,
    summary: "",
    images: [],
    canonicalUrl: "",
    thumbnail: "",
    brand: "",
    model: "",
    processor: "",
    ramSlot: "",
    repairs: [
      {
        problem: "",
        solutions: [""],
      },
    ],
    repairTips: [""],
    downloads: [],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (isEdit && note) {
      setFormData({
        ...note,
        tags: note.tags || [],
        images: note.images || [],
        repairs: note.repairs?.map((repair) => {
          if (typeof repair === "string") {
            return { problem: repair, solutions: [""] };
          } else {
            return {
              problem: repair.problem || "",
              solutions: Array.isArray(repair.solutions)
                ? repair.solutions
                : [""],
            };
          }
        }) || [{ problem: "", solutions: [""] }],
        repairTips: note.repairTips || [""],
        downloads: note.downloads || [],
        date: moment(note.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
        lastmod: moment().format("DD/MM/YYYY"), // Atualiza lastmod com a data atual na edição
      });
    } else {
      const formattedDate = moment().format("DD/MM/YYYY");
      setFormData((prevFormData) => ({
        ...prevFormData,
        date: formattedDate,
        lastmod: formattedDate,
      }));
    }
  }, [isEdit, note]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleArrayChange = (e, index, arrayName) => {
    const { value } = e.target;
    const newArray = [...formData[arrayName]];
    newArray[index] = value;
    setFormData({ ...formData, [arrayName]: newArray });
  };

  const handleRemoveDownload = (index) => {
    const newDownloads = [...formData.downloads];
    newDownloads.splice(index, 1);
    setFormData({ ...formData, downloads: newDownloads });
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const uploadFiles = async () => {
    const uploadPromises = files.map(async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axiosInstance.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return response.data; // Assuming the server returns the file info
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
      }
    });

    return Promise.all(uploadPromises);
  };

  const handleAddRepairTip = () => {
    setFormData({ ...formData, repairTips: [...formData.repairTips, ""] });
  };

  const handleRemoveRepairTip = (index) => {
    const newRepairTips = [...formData.repairTips];
    newRepairTips.splice(index, 1);
    setFormData({ ...formData, repairTips: newRepairTips });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const uploadedFiles = await uploadFiles();
      const updatedDownloads = [
        ...formData.downloads,
        ...uploadedFiles.map((file) => ({
          file: file.fileName,
          size: file.size,
          downloads: 0,
          image: file.imageUrl,
        })),
      ];

      const formattedData = {
        ...formData,
        date: moment(formData.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
        lastmod: moment(formData.lastmod, "DD/MM/YYYY").format("YYYY-MM-DD"),
        downloads: updatedDownloads,
      };

      const url = isEdit ? `/api/notes/${id}` : "/api/notes";
      const method = isEdit ? "put" : "post";

      await axiosInstance({
        method,
        url,
        data: formattedData,
        headers: { "Content-Type": "application/json" },
      });

      navigate("/");
    } catch (error) {
      console.error(error);
      setError(
        "An error occurred while submitting the form. Please check your input and try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-lg mx-auto my-4 p-6 bg-white shadow-md rounded-lg"
    >
      {error && <div className="mb-4 text-red-500">{error}</div>}
      <button onClick={() => navigate("/")} className="px-4 py-2 rounded-md bg-green-700">BACK</button>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">Title:</label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">Date:</label>
        <input
          type="text"
          name="date"
          value={formData.date}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">
          Last Modified:
        </label>
        <p className="w-full px-3 py-2 border rounded-md bg-gray-100">
          {formData.lastmod}
        </p>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">
          Tags (comma-separated):
        </label>
        <input
          type="text"
          name="tags"
          value={formData.tags.join(", ")}
          onChange={(e) =>
            setFormData({
              ...formData,
              tags: e.target.value.split(",").map((tag) => tag.trim()),
            })
          }
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">Draft:</label>
        <input
          type="checkbox"
          name="draft"
          checked={formData.draft}
          onChange={() => setFormData({ ...formData, draft: !formData.draft })}
          className="form-checkbox h-5 w-5 text-blue-600"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">
          Summary:
        </label>
        <input
          type="text"
          name="summary"
          value={formData.summary}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">
          Images (comma-separated):
        </label>
        <input
          type="text"
          name="images"
          value={formData.images.join(", ")}
          onChange={(e) =>
            setFormData({
              ...formData,
              images: e.target.value.split(",").map((image) => image.trim()),
            })
          }
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">
          Canonical URL:
        </label>
        <input
          type="text"
          name="canonicalUrl"
          value={formData.canonicalUrl}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">
          Thumbnail:
        </label>
        <input
          type="text"
          name="thumbnail"
          value={formData.thumbnail}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">Brand:</label>
        <input
          type="text"
          name="brand"
          value={formData.brand}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">Model:</label>
        <input
          type="text"
          name="model"
          value={formData.model}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">
          Processor:
        </label>
        <input
          type="text"
          name="processor"
          value={formData.processor}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">
          RAM Slot:
        </label>
        <input
          type="text"
          name="ramSlot"
          value={formData.ramSlot}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>

      <RepairSection
        repairs={formData.repairs}
        setFormData={setFormData}
        formData={formData}
      />

      <RepairTipsSection
        repairTips={formData.repairTips}
        handleArrayChange={handleArrayChange}
        handleAddRepairTip={handleAddRepairTip}
        handleRemoveRepairTip={handleRemoveRepairTip}
      />

      <DownloadsSection
        downloads={formData.downloads}
        handleArrayChange={handleArrayChange}
        handleRemoveDownload={handleRemoveDownload}
        handleFileChange={handleFileChange}
      />

      <button
        type="submit"
        className={`px-4 py-2 rounded-md ${
          isSubmitting ? "bg-gray-400" : "bg-blue-500 text-white"
        }`}
        disabled={isSubmitting}
      >
        {isEdit ? "Update" : "Create"}
      </button>
    </form>
  );
};

export default NoteForm;
