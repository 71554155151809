import React from 'react';

const DownloadsSection = ({ downloads, handleArrayChange, handleRemoveDownload, handleFileChange }) => {
  return (
    <div>
      {downloads.map((download, index) => (
        <div key={index} className="mb-6 p-4 border rounded-md">
          <label className="block text-gray-700 font-semibold mb-2">Download File:</label>
          <input type="text" name="file" value={download.file} onChange={(e) => handleArrayChange(e, index, 'downloads')} className="w-full px-3 py-2 border rounded-md mb-2" />
          <label className="block text-gray-700 font-semibold mb-2">Download Size:</label>
          <input type="text" name="size" value={download.size} onChange={(e) => handleArrayChange(e, index, 'downloads')} className="w-full px-3 py-2 border rounded-md mb-2" />
          <label className="block text-gray-700 font-semibold mb-2">Downloads:</label>
          <input type="number" name="downloads" value={download.downloads} onChange={(e) => handleArrayChange(e, index, 'downloads')} className="w-full px-3 py-2 border rounded-md mb-2" />
          <label className="block text-gray-700 font-semibold mb-2">Download Image:</label>
          <input type="text" name="image" value={download.image} onChange={(e) => handleArrayChange(e, index, 'downloads')} className="w-full px-3 py-2 border rounded-md mb-2" />
          <button type="button" onClick={() => handleRemoveDownload(index)} className="mt-2 px-4 py-2 bg-red-500 text-white rounded-md">Remove</button>
        </div>
      ))}
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">Upload Files:</label>
        <input type="file" multiple onChange={handleFileChange} className="w-full px-3 py-2 border rounded-md" />
      </div>
    </div>
  );
};

export default DownloadsSection;
